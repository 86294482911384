import { has, isNil } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, put, getContext, select } from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import { CLIENT_CONTEXT } from '@peloton/api';
import { redirectToNotFound } from '@peloton/navigation';
import type { RequireAtLeastOne } from '@peloton/types';
import { loadClassDetailSaga } from '@engage/class-detail';
import type { Peloton, Class } from '@engage/classes';
import { fetchPeloton, getClass } from '@engage/classes';
import { getFilterAnalyticsProps } from '@engage/filters';
import type { SearchSharedProperties } from '@engage/library';
import type { DeviceType } from '@engage/workouts';
import { loadWorkoutById } from '@engage/workouts';
// eslint-disable-next-line no-restricted-imports
import { getFeatureToggle } from '@members/feature-toggles/redux';
import { createStream, createPeloton, createWorkoutV2, createWorkout } from '../../api';
import toHttpsUrl from '../../mappers';
import type {
  StreamResult,
  VideoStream,
  StreamLimitReached,
  NoActiveSubscription,
} from '../../models';
import { StreamResultKind, isVideoStream } from '../../models';
import type { WorkoutStreamState } from '../../redux';
import { updateVideoActiveView, VideoActiveViewState } from '../../redux';
import { config } from './config';

export type InitializeWorkoutParams = {
  inProgressWorkoutId?: string;
  deviceType: DeviceType;
  analytics?: WorkoutAnalyticsParams;
  requireVODAvailability?: boolean;
} & ClassIdOrPelotonId;

type WorkoutAnalyticsParams = {
  source?: string;
  programProgressId?: string;
  searchSharedProperties?: SearchSharedProperties;
  featureModule?: string;
};

type ClassIdOrPelotonId = RequireAtLeastOne<Partial<HasPelotonId> & Partial<HasClassId>>;

// VOD classes will be initialized with a classId
type HasClassId = { classId: string };

// Live classes will be initialized with a pelotonId
type HasPelotonId = { pelotonId: string };

type CreateWorkoutStreamParams = InitializeWorkoutParams &
  HasClassId &
  HasPelotonId & { streamResult: VideoStream; analytics?: WorkoutAnalyticsParams };

export const hasClassId = (p: ClassIdOrPelotonId): p is HasClassId => has('classId', p);

const hasPelotonId = (p: ClassIdOrPelotonId): p is HasPelotonId => has('pelotonId', p);

export const getOrCreatePeloton = function* (
  idForLookup: ClassIdOrPelotonId,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  let peloton: Peloton;

  if (hasPelotonId(idForLookup)) {
    peloton = yield call(fetchPeloton, client, idForLookup.pelotonId);
    return peloton;
  } else if (hasClassId(idForLookup)) {
    peloton = yield call(createPeloton, client, idForLookup.classId);
    return peloton;
  } else {
    throw new Error('One of classId or pelotonId must be supplied');
  }
};

export const obtainStream = function* (
  classId: string,
  contentType: string,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const streamResult: StreamResult = yield call(
    createStream,
    client,
    classId,
    contentType,
  );
  if (isVideoStream(streamResult)) {
    return streamResult;
  } else {
    yield call(dealWithFailedStream, streamResult);
  }

  return undefined;
};

export const toWorkoutStream = function* (params: CreateWorkoutStreamParams) {
  const workoutId: string = yield call(getOrCreateWorkout, params);

  const klass: Class = yield call(loadClassById, params.classId);

  /**
   * In `mapRideToClass` we default non-scenic and non-live classes to on-demand classes.
   * However, it is possible for classes to be non-on-demand and also not have a vod_stream_url.
   */
  const url = toHttpsUrl(klass);
  if (!url) {
    yield put(redirectToNotFound());
    return undefined;
  }

  const stream: Required<WorkoutStreamState> = {
    streamHistoryId: params.streamResult.streamHistoryId,
    workoutId,
    url: params.streamResult.url,
  };
  return stream;
};

const dealWithFailedStream = function* (
  streamResult: StreamLimitReached | NoActiveSubscription,
) {
  switch (streamResult.kind) {
    case StreamResultKind.NoActiveSubscription:
      yield put(updateVideoActiveView(VideoActiveViewState.Paywall));
      break;
    case StreamResultKind.StreamLimitReached:
      yield put(updateVideoActiveView(VideoActiveViewState.StreamLimit));
      break;
    default:
      assertStreamResult(streamResult);
  }
};

export const loadClassById = function* (classId: string) {
  yield call(loadClassDetailSaga, classId);
  const klass: ReturnType<typeof getClass> = yield select(getClass, classId);
  return klass;
};

export const getOrCreateWorkout = function* (
  params: CreateWorkoutStreamParams,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const deviceLanguage = client.defaults.headers.common['Accept-Language'];
  let workoutId: string;

  if (isNil(params.inProgressWorkoutId)) {
    const isWorkoutV2Enabled = yield select(getFeatureToggle, 'important_stuff');
    const analyticsProps = yield call(toCreateWorkoutAnalyticsProps, params);

    if (isWorkoutV2Enabled) {
      const workoutParamsV2 = {
        pelotonId: params.pelotonId,
        classId: params.classId,
        deviceType: params.deviceType,
        deviceLanguage,
        analyticsProps,
        subscriptionId: params.streamResult.streamSubscriptionId,
      };

      workoutId = yield call(createWorkoutV2, client, workoutParamsV2);
    } else {
      const workoutParams = {
        pelotonId: params.pelotonId,
        classId: params.classId,
        isDigital: true,
        deviceType: params.deviceType,
        deviceLanguage,
        analyticsProps,
        subscriptionType: params.streamResult.streamSubscriptionIdType,
        subscriptionId: params.streamResult.streamSubscriptionId,
      };
      workoutId = yield call(createWorkout, client, workoutParams);
    }
  } else {
    workoutId = params.inProgressWorkoutId;
  }

  yield call(loadWorkoutById, client, workoutId);
  return workoutId;
};

const toCreateWorkoutAnalyticsProps = function* (
  params: CreateWorkoutStreamParams,
): SagaIterator {
  const screenProps = yield call(getScreenPropsSaga);
  const browseInfo = yield select(getFilterAnalyticsProps);
  const sourceProp = params.analytics?.source
    ? { Source: params.analytics.source }
    : undefined;
  const programsProp = params.analytics?.programProgressId
    ? { '[Program Progress ID]': params.analytics.programProgressId }
    : undefined;
  const searchProps = params.analytics?.searchSharedProperties ?? undefined;
  const featureModule = params.analytics?.featureModule
    ? { '[Featured Name]': params.analytics?.featureModule }
    : undefined;

  const knownAnalyticsProps = {
    ...screenProps,
    ...browseInfo,
    ...sourceProp,
    ...programsProp,
    ...searchProps,
    ...featureModule,
  };

  return yield call(config.getAnalyticsPropsForStartWorkout, knownAnalyticsProps);
};

/**
 * Generates stream url with tokenized access for Akamai.
 * @param videoUrl Unauthenticated stream url.
 * @param token Akamai token.
 * @param classId Class ID passed through as URL param for Chromecast Receiver. (See apps/cast-receiver/src/lib/timelineManager.ts).
 */
export const toTokenizedUrl = (videoUrl: string, token: string, classId: string) =>
  `${videoUrl}?hdnea=${encodeURIComponent(token)}&classId=${classId}`;

const assertStreamResult = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};
