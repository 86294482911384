import type { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components';
import { getUserId } from '@peloton/auth';
import { tiersGradient } from '@engage/colors';
import type { FitnessDisciplineSlug } from '@engage/metadata';
import { useReduxState } from '@engage/redux/hooks';
import { Tiering, useTiering } from '@members/copy';
import { Feature, useFeatureVariables } from '@members/feature-toggles';
import type { FitnessDisciplineSlug as GQLFitnessDisciplineSlug } from '@members/graphql-swr/types.generated';
import { useClassAccessType } from '@members/tiering/hooks/useClassAccessType';
import { useDoesClassImpactQuota } from '@members/tiering/hooks/useDoesClassImpactQuota';
import { useIsFreemiumClass } from '@members/tiering/hooks/useIsFreemiumClass';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';
import { useRemainingClassesQuota } from '@members/tiering/hooks/useRemainingClassesQuota';
import type { ClassAvailabilityProps } from '@members/tiering/models';
import { CLASS_ACCESS_TYPE } from '@members/tiering/models';
import { useTierContext } from '@members/tiering/TierProvider';
import { shouldShowQuotaReminderModal } from '@members/tiering/utils';
import { CLASS_PLAY_BUTTON_SOURCE } from './classPlayButtonSource';

type PlayButtonProperties = {
  shouldShowPlayIcon: boolean;
  isDisabled: boolean;
  upgradeCopy: string | null;
  startHandler: () => void;
  backgroundStyles: FlattenSimpleInterpolation;
  isCurrentlyPlayable: boolean;
};

export const useOnDemandButtonHandler = (
  classId: string,
  suggestedClickHandler: () => void,
  fitnessDisciplineSlug?: FitnessDisciplineSlug | GQLFitnessDisciplineSlug,
  classAvailabilityProps?: ClassAvailabilityProps,
  source = CLASS_PLAY_BUTTON_SOURCE.NONE,
): PlayButtonProperties => {
  const classAccessType = useClassAccessType(classId, classAvailabilityProps);
  const {
    tier: { tierType },
    isEligibleForFreeTrial,
    refetch,
  } = useTierContext();
  const [isStickyDogCycleEnabled, { trialLengthInDays }] = useFeatureVariables(
    Feature.Stickydogcycle,
  );
  const { setModal, setModalClassHandler } = useTierContext();
  const upgradeToStartCopy = useTiering(Tiering.UpgradeToStart);
  const upgradeCopy = useTiering(Tiering.Upgrade);
  const oneMonthTrialCopy = useTiering(Tiering.StartOneMonthTrial, {
    numberOfDays: isStickyDogCycleEnabled ? trialLengthInDays : 30,
  });
  const isFreemiumMember = useIsFreemiumMember();
  const isFreemiumClass = useIsFreemiumClass(classId, classAvailabilityProps);
  const userId = useReduxState(getUserId);
  const doesClassImpactQuota = useDoesClassImpactQuota(classId);
  const remainingClassesQuota = useRemainingClassesQuota();
  const shouldShowReminderModal =
    doesClassImpactQuota && shouldShowQuotaReminderModal(userId);

  // Show 'Upgrade' for start buttons in this list, 'Upgrade to Start' otherwise
  let buttonCopy = [
    CLASS_PLAY_BUTTON_SOURCE.PROGRAMS,
    CLASS_PLAY_BUTTON_SOURCE.HOMESCREEN_EXTRAS_SCHEDULED_CLASSES,
    CLASS_PLAY_BUTTON_SOURCE.STACKS_TRANSITION,
  ].includes(source)
    ? upgradeCopy
    : upgradeToStartCopy;

  if (isEligibleForFreeTrial && source === CLASS_PLAY_BUTTON_SOURCE.ON_DEMAND_MODAL) {
    buttonCopy = oneMonthTrialCopy;
  }

  if (isFreemiumMember && !isFreemiumClass) {
    return {
      shouldShowPlayIcon: false,
      isDisabled: false,
      upgradeCopy: buttonCopy,
      startHandler: suggestedClickHandler,
      backgroundStyles:
        classAccessType === CLASS_ACCESS_TYPE.EXCLUSIVE || isEligibleForFreeTrial
          ? tiersGradient
          : css``,
      isCurrentlyPlayable: false,
    };
  }

  if (tierType === 'APP') {
    if (classAccessType === CLASS_ACCESS_TYPE.EXCLUSIVE) {
      const startHandler = () => {
        setModal({
          modalType: 'upgrade_modal',
          isOpen: true,
          source: source?.toString(),
        });
      };
      return {
        shouldShowPlayIcon: false,
        isDisabled: false,
        upgradeCopy: buttonCopy,
        startHandler: startHandler,
        backgroundStyles: tiersGradient,
        isCurrentlyPlayable: false,
      };
    }

    if (classAccessType === CLASS_ACCESS_TYPE.PREMIUM) {
      refetch();
      if (remainingClassesQuota < 1) {
        const startHandler = () => {
          setModal({
            modalType: 'no_quota_available_modal',
            isOpen: true,
            source: source?.toString(),
          });
        };

        // Note: Special case for stacked non-exclusive, premium class for user with no quota
        // The start handling remains same, but we want to show 'start' instead of 'upgrade'
        if (source === CLASS_PLAY_BUTTON_SOURCE.STACKS_ACTIVE_STATE) {
          return {
            shouldShowPlayIcon: true,
            isDisabled: false,
            upgradeCopy: null,
            startHandler: startHandler,
            backgroundStyles: css``,
            isCurrentlyPlayable: false,
          };
        }

        return {
          shouldShowPlayIcon: false,
          isDisabled: false,
          upgradeCopy: buttonCopy,
          startHandler: startHandler,
          backgroundStyles: tiersGradient,
          isCurrentlyPlayable: false,
        };
      }
      if (shouldShowReminderModal) {
        const startHandler = () => {
          setModal({
            modalType: 'quota_reminder_modal',
            isOpen: true,
            source: source?.toString(),
          });
          setModalClassHandler({
            classId: classId,
            isOnDemand: true,
            suggestedOnClick: suggestedClickHandler,
            pelotonId: '',
          });
        };
        return {
          shouldShowPlayIcon: true,
          isDisabled: false,
          upgradeCopy: null,
          startHandler: startHandler,
          backgroundStyles: css``,
          isCurrentlyPlayable: true,
        };
      }
      return {
        shouldShowPlayIcon: true,
        isDisabled: false,
        upgradeCopy: null,
        startHandler: suggestedClickHandler,
        backgroundStyles: css``,
        isCurrentlyPlayable: true,
      };
    }
  }

  return {
    shouldShowPlayIcon: true,
    isDisabled: false,
    upgradeCopy: null,
    startHandler: suggestedClickHandler,
    backgroundStyles: css``,
    isCurrentlyPlayable: true,
  };
};
