import type { UserAttributes } from '@optimizely/optimizely-sdk';
import type { SignedInUser, User } from '@peloton/auth';
import { getUser } from '@peloton/auth';
import { toLocale } from '@peloton/internationalize';
import { useReduxState } from '@engage/redux';
import { isOk } from '@engage/result';
import { StorageKeys, isProdEnv, syncWithQueryParam } from '@members/env';
import type { experiments } from '@members/feature-toggles/toggles';
import useIPAddress from '@members/toggle-gate/useIPAddress';
import { useClient } from './client';
import { getAjsUserId } from './utils';

export type ExperimentFeature = keyof typeof experiments;
export type ExperimentVariation = typeof experiments[ExperimentFeature][number]['variations'][number];
export type ExperimentKey = typeof experiments[ExperimentFeature][number]['key'];

const getMemberGroup = (user?: User) =>
  !!user
    ? (user as SignedInUser)?.memberGroups?.reduce(
        (acc, elem) => ({
          ...acc,
          [elem]: true,
        }),
        {} as Record<string, boolean>,
      )
    : {};

export const useExperimentVariation = (
  experimentKey: ExperimentKey,
  attributes: UserAttributes = {},
) => {
  const user = useReduxState(getUser);
  const memberGroups = getMemberGroup(user);

  const ip_address = useIPAddress();
  const locale = toLocale();

  const userId = getAjsUserId();
  const [client] = useClient();
  const localOverride = syncWithQueryParam(StorageKeys.FeatureExperiments);
  const experimentLocalStorage = !!localOverride && JSON.parse(localOverride);
  let overrideValue, originalValue;
  if (!isProdEnv() && experimentLocalStorage?.[experimentKey]) {
    overrideValue = experimentLocalStorage?.[experimentKey];
  }
  if (userId && isOk(client)) {
    originalValue = client.value.getVariation(experimentKey, userId, {
      ...memberGroups,
      ip_address,
      locale,
      ...attributes,
    });
  }
  return {
    originalValue: originalValue as ExperimentVariation,
    variationName: (overrideValue ?? originalValue) as ExperimentVariation,
  };
};
