import React from 'react';
import type { SignedInUser } from '@peloton/auth';
import { getUser } from '@peloton/auth';
import { toLocale } from '@peloton/internationalize';
// eslint-disable-next-line no-restricted-imports
import { isProdEnv } from '@engage/env/env';
import { useReduxState } from '@engage/redux';
import type { Shortcuts } from '@members/env';
import { syncedQueryParamToShortcutKey, StorageKeys } from '@members/env';
import type { Environments } from '@members/feature-toggles/datafiles';
import { OptimizelyDataFilePaths, Environment } from '@members/feature-toggles/datafiles';
import defaultQA from '@members/feature-toggles/default/GnyGx71RMLkBMy54SG1sMi.default';
import defaultProd from '@members/feature-toggles/default/JSy82M5bxf9FFZUk3c9z7g.default';
import { provideToggleGate } from '@members/feature-toggles/provideToggleGate';
import { provideVariableGate } from '@members/feature-toggles/provideVariableGate';
import { variables } from '@members/feature-toggles/toggles';
import { InitOptimizelyClient } from '@members/optimizely/client';
import useIPAddress from './useIPAddress';

const EnvironmentShortcuts: Shortcuts = {
  [Environment.QA]: ['q', 'qa'],
  [Environment.Production]: ['p', 'prod', 'production'],
};
const getDefaultEnv = () => (isProdEnv() ? Environment.Production : Environment.QA);

const getDatafileUrl = (optimizelyEnv: Environment) => {
  const datafilePath =
    optimizelyEnv === Environment.QA
      ? OptimizelyDataFilePaths.QA
      : OptimizelyDataFilePaths.Production;

  if (process.env.IS_PUBLIC) {
    return datafilePath;
  }
  return `https://cdn.optimizely.com${datafilePath}`;
};

export const OptimizelyEnv: Environments =
  (syncedQueryParamToShortcutKey(
    StorageKeys.OptimizelyEnv,
    EnvironmentShortcuts,
  ) as Environments) ?? getDefaultEnv();

const datafileUrl = getDatafileUrl(OptimizelyEnv);

const defaultToggleData = OptimizelyEnv === Environment.QA ? defaultQA : defaultProd;

export const getOptimizelySessionId = (): string => {
  const isSessionStateKey = (key: string): boolean =>
    /^optimizely.+session_state$/.test(key);

  if (typeof window !== 'undefined') {
    for (const key in localStorage) {
      if (isSessionStateKey(key)) {
        try {
          const sessionState = JSON.parse(
            localStorage.getItem(key) ?? JSON.stringify({ sessionId: '' }),
          );
          return sessionState?.sessionId ?? '';
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  return '';
};

const ProvidedToggleGate = provideToggleGate(provideVariableGate({ variables }));

export const ToggleGate: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof ProvidedToggleGate>>
> = ({ children, ...props }) => {
  const user = useReduxState(getUser);
  const memberGroups = !!user
    ? (user as SignedInUser)?.memberGroups?.reduce(
        (acc, elem) => ({
          ...acc,
          [elem]: true,
        }),
        {} as Record<string, boolean>,
      )
    : {};

  const ip_address = useIPAddress();
  const locale = toLocale();
  return (
    <>
      <InitOptimizelyClient
        defaultDatafile={defaultToggleData}
        dataFileUrl={datafileUrl}
      />
      <ProvidedToggleGate
        userId={props.userId}
        attributes={{ ...memberGroups, ip_address, locale }}
      >
        {children}
      </ProvidedToggleGate>
    </>
  );
};
