import { prop, merge, mergeAll } from 'ramda';
import type { ClientDetails, ScreenProps } from '@peloton/analytics';
import { toClientDetailsHeaderWithoutStringify } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { Locale } from '@peloton/internationalize';
import { toNowTimezoneString } from '@peloton/time';
import type { ApiSegment } from '@engage/class-detail';
import { toPeloton } from '@engage/classes';
import type { FilterAnalyticsProps } from '@engage/filters';
import type { DeviceType } from '@engage/workouts';
import { toTimeline } from './mappers';
import type {
  VideoStream,
  StreamResult,
  Packet,
  MetricReportV2,
  LeaderboardPacket,
} from './models';
import { StreamResultKind, toStreamLimitReached, toNoActiveSubscription } from './models';

enum CreateStreamErrorCode {
  NoActiveSubscription = 3031,
  StreamLimitReached = 3032,
}

export const createStream = (
  api: Client,
  classId: string,
  contentType: string,
): Promise<StreamResult> =>
  api
    .post<VideoStream>(
      `/api/ride/${classId}/stream?content_type=${contentType}`,
      {},
      toSkipErrorHandlingConfig(),
    )
    .then(pipeData(merge({ kind: StreamResultKind.VideoStream })))
    .catch(err => {
      const code: number = err.responseBody
        ? err?.responseBody?.errorCode
        : err?.response?.data?.errorCode;
      console.error('createStream error', err);
      switch (code) {
        case CreateStreamErrorCode.StreamLimitReached:
          return toStreamLimitReached();
        case CreateStreamErrorCode.NoActiveSubscription:
          return toNoActiveSubscription();
        default:
          throw err;
      }
    });

const toStreamHistoryUrl = (streamHistoryId: string) =>
  `api/stream_history/${streamHistoryId}`;

export const destroyStream = (api: Client, streamHistoryId: string): Promise<unknown> =>
  api.delete(toStreamHistoryUrl(streamHistoryId), toSkipErrorHandlingConfig());

export const heartbeatStream = (api: Client, streamHistoryId: string) =>
  api.post(
    `${toStreamHistoryUrl(streamHistoryId)}/heartbeat`,
    {},
    toSkipErrorHandlingConfig(),
  );

const CREATE_PELOTON_URL = '/api/peloton';

export const createPeloton = (api: Client, classId: string) =>
  api.post(CREATE_PELOTON_URL, { rideId: classId }).then(pipeData(toPeloton));

const CREATE_WORKOUT_V2_URL = '/api/v2/workout';

type CreateWorkoutV2Param = {
  classId?: string;
  pelotonId: string;
  deviceType: DeviceType;
  deviceLanguage: Locale;
  analyticsProps?: ClientDetails<ScreenProps> & FilterAnalyticsProps;
  subscriptionId?: string;
};

export const createWorkoutV2 = (api: Client, param: CreateWorkoutV2Param) => {
  const data = {
    pelotonId: param.pelotonId,
    rideId: param.classId,
    subscriptionId: param.subscriptionId,
    streamSubscriptionId: param.subscriptionId,
    deviceType: param.deviceType,
    workoutType: 'class',
  };

  const config = {
    headers: mergeAll([
      { 'Peloton-Client-Date': toNowTimezoneString() },
      toClientDetailsHeaderWithoutStringify(param.analyticsProps).headers,
      { 'Accept-Language': param.deviceLanguage },
    ]),
    ...toSkipErrorHandlingConfig(),
  };

  return api.post(CREATE_WORKOUT_V2_URL, data, config).then(pipeData(prop('id')));
};

const CREATE_WORKOUT_URL = '/api/workout';

type CreateWorkoutParam = {
  classId?: string;
  pelotonId: string;
  isDigital: boolean;
  deviceType: DeviceType;
  deviceLanguage: Locale;
  analyticsProps?: ClientDetails<ScreenProps> & FilterAnalyticsProps;
  subscriptionType?: string;
  subscriptionId?: string;
  bikeNumber?: number;
};

export const createWorkout = (api: Client, param: CreateWorkoutParam) => {
  const data = {
    pelotonId: param.pelotonId,
    rideId: param.classId,
    subscriptionId: param.subscriptionId,
    streamSubscriptionId: param.subscriptionId,
    streamSubscriptionIdType: param.subscriptionType,
    deviceType: param.deviceType,
    deviceLanguage: param.deviceLanguage,
    workoutType: 'class',
    isDigital: param.isDigital,
    bikeNumber: param.bikeNumber,
  };

  const config = {
    headers: mergeAll([
      { 'Peloton-Client-Date': toNowTimezoneString() },
      toClientDetailsHeaderWithoutStringify(param.analyticsProps).headers,
      { 'Accept-Language': param.deviceLanguage },
    ]),
    ...toSkipErrorHandlingConfig(),
  };

  return api.post(CREATE_WORKOUT_URL, data, config).then(pipeData(prop('id')));
};

const toLoadTimeline = (id: string) => `/api/ride/${id}/timeline`;

export const fetchClassTimeline = (api: Client, id: string) =>
  api.get(toLoadTimeline(id)).then(pipeData(toTimeline));

export type ApiTimeline = {
  classStartOffset: number;
  classEndOffset: number;
  videoEndOffset: number;
  segments: ApiSegment[];
};

const toEndWorkoutUrl = (workoutId: string) => `api/workout/${workoutId}/end`;

export const endWorkout = (api: Client, workoutId: string): Promise<unknown> =>
  api.post(toEndWorkoutUrl(workoutId));

const toSendPacketsUrl = (workoutId: string) => `/stats/workout/${workoutId}/packets`;
const toSendPacketUrl = 'stats/packet';

// TODO: Have Callisto move to pluralized
export const toSendPackets = (api: Client, workoutId: string, packet: Packet) => {
  const data = { packets: [packet] };
  api.post(toSendPacketsUrl(workoutId), data);
};

export const sendStatPackets = (api: Client, workoutId: string, packets: Packet[]) =>
  api.post(toSendPacketsUrl(workoutId), { packets }, toSkipErrorHandlingConfig());

// Use this single packet endpoint instead of the batch packet endpoint if you want to show up in leader board rankings
export const sendStatPacket = (api: Client, packet: LeaderboardPacket) => {
  api.post(toSendPacketUrl, packet);
};

const metricsV2Url = '/api/metrics/v2/video';

export const reportMetricsV2 = (api: Client, metrics: MetricReportV2) =>
  api.post(metricsV2Url, metrics, toSkipErrorHandlingConfig());
