import { useOauth } from '@peloton/auth';
import { useOneTrust as usePeloOneTrust } from '@peloton/onetrust/useOneTrust';
import { isPathAllowed } from '@members/modal/Modal';

declare global {
  interface Window {
    buildOneTrustScripts: () => void;
  }
}

export const useOneTrust = () => {
  const { getIsAuthenticated } = useOauth();
  const {
    toggleInfoDisplay,
    getGeolocationData,
    getIsAlertBoxClosed,
    isDoNotSellEnabled,
    isReady,
  } = usePeloOneTrust();

  const maybeLoadOneTrust = async () => {
    const isAuthenticated = await getIsAuthenticated();

    if (
      isAuthenticated &&
      !!window?.buildOneTrustScripts &&
      isPathAllowed(window.location.pathname)
    ) {
      window.buildOneTrustScripts();
    }
  };

  return {
    toggleInfoDisplay,
    getGeolocationData,
    getIsAlertBoxClosed,
    areCookieSettingsEnabled: isReady,
    isDoNotSellEnabled,
    maybeLoadOneTrust,
  };
};
