import useSWR from 'swr';
import { AuthFetchers } from '@members/data-fetch';

const useIPAddress = () => {
  const { data } = useSWR(
    ...AuthFetchers.GetIpAddress({}, {}, { revalidateOnFocus: false }),
  );

  return data?.clientIpAddress ?? '';
};

export default useIPAddress;
