import { datadogRum } from '@datadog/browser-rum';
import { push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import type { AxiosErrorWithMeta, Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { browserProvider } from '@peloton/newrelic';
import { loadClassDetailSaga } from '@engage/class-detail';
import { loadClassSuccess } from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import { deleteWorkout, fetchWorkoutDetails } from './api';
import { isClassWorkout } from './models';
import type { WorkoutRequestAction, WorkoutRequestSuccessAction } from './redux';
import { loadWorkoutSuccess, removeWorkoutSuccess, WorkoutActionType } from './redux';

export const loadWorkoutById = function* (client: Client, workoutId: string) {
  try {
    const { workout, klass } = yield call(fetchWorkoutDetails, client, workoutId);
    yield put(loadWorkoutSuccess(workout));
    if (klass) {
      yield put(loadClassSuccess(klass));
    }
  } catch (e) {
    const axiosErr = e as AxiosErrorWithMeta;
    if (axiosErr.response?.status == 403) {
      const actionReporter = browserProvider.getActionReporter();
      actionReporter('Member viewed private legacy workout', {});
      yield put(push('/'));
    } else {
      throw e;
    }
  }
};

export const loadWorkoutSaga = function* (client: Client, action: WorkoutRequestAction) {
  yield call(loadWorkoutById, client, action.payload.id);
};

export const deleteWorkoutSaga = function* (
  client: Client,
  workoutId: string,
  source?: string,
): SagaIterator {
  try {
    const screenProps = yield call(getScreenPropsSaga);
    const analyticsProps = source ? { ...screenProps, source: source } : screenProps;
    datadogRum.addAction('@engage > workouts > sagas | deleteWorkout', {
      workoutId: workoutId,
    });
    yield call(deleteWorkout, client, workoutId, analyticsProps);
    yield put(removeWorkoutSuccess(workoutId));
  } catch (e) {
    logError(e, 'deleteWorkoutSaga');
  }
};

export const loadClassFromWorkoutSaga = function* (action: WorkoutRequestSuccessAction) {
  const workout = action.payload;
  if (isClassWorkout(workout)) {
    yield call(loadClassDetailSaga, workout.classId);
  }
};

export const workoutsSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(WorkoutActionType.Request, loadWorkoutSaga, client);
  yield takeEvery(WorkoutActionType.RequestSuccess, loadClassFromWorkoutSaga);
};
